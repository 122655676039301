
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import { Field } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as Yup from 'yup';
import { useBus } from '../../../../bus';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import TraineeVue from '@/layout/Trainee.vue';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'kt-drawer-trainee-edit',
  components: { Field },
  props: {
    data: { type: Object },
  },

  data() {
    return {
      api_url: '',
      districts: [],
      subDistricts: [],
      subPermanentDistrict: [],
      subHomeSubDistrict: [],
      empDistricts: [],
      empHomeDistricts: [],
      loading: false,
      url: '',
      formData: {
        id: '',
        reference_number: '',
        registration_number: '',
        trainee_name: '',
        gender: '',
        nid: '',
        bcn: '',
        date_of_birth: '',
        present_address: '',
        present_post_code: '',
        present_district: '',
        present_upazilla: '',
        photo: '' as any,
        per_address: '',
        per_post_code: '',
        per_district: '',
        per_upazilla: '',
        home_district: '',
        home_upazilla: '',
        mobile: '',
        alternative_mobile: '',
        religion: '',
        ethnic_group: '',
        highest_class_completed: '',
        highest_class_completed_year: '',
        is_employed: '',
        year_of_experience: '',
        family_monthly_income: '',
        is_physically_challenged: '',
        challenge_remarks: '',
        mother_name: '',
        mother_education_level: '',
        mother_occupation: '',
        father_name: '',
        father_education_level: '',
        father_occupation: '',
        father_annual_income: '',
        have_family_owned_home: '',
        have_family_owned_land: '',
        number_of_siblings: '',
      },
      currentYear: new Date().getFullYear(),
      tranche: [],
      validated: 1,
      association: [],
      ethnicGroups: [],
      ttt: [],
      targetYears: [],

      load: false,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    this.load = true;
    this.emitter.on('trainee-edit', async (data) => {
      this.formData = data;
      await this.getDistrictForEmp();
      await this.getSubDistrict();
      await this.getPermanentSubDistrict();
      await this.getHomeSubDistrict();
      await this.getEthnicGroup();
      setTimeout(() => (this.load = false), 2000);
      if(data.bcn){
        this.validated= 0;
      }else{
        this.validated= 1;
      }
      if(VueCookieNext.getCookie('_seip_role_id') == 1){
         this.validated= 0;
      }

      // await this.targetLog();
    });
    // await this.getDivision();
  },
  methods: {
    traineePhoto(e) {
      this.formData.photo = e.target.files[0];
      this.url = URL.createObjectURL(this.formData.photo);
    },
    async formSubmit() {
      let formData = new FormData();
      let user_id = VueCookieNext.getCookie('_seip_user');
      formData.set('user_id', user_id.id);
      formData.set('photo', this.formData.photo);
      for (var key in this.formData) {
        formData.set(key, this.formData[key]);
      }
      this.loading = true;
      await ApiService.post('trainee/update', formData)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              DrawerComponent?.hideAll();
              this.emitter.emit('trainee-updated', true);
              // this.formData = {
              //   id: "",
              //   reference_number: "",
              //   trainee_name: "",
              //   gender: "",
              //   nid: "",
              //   bcn: "",
              //   date_of_birth: "",
              //   present_address: "",
              //   present_post_code: "",
              //   present_district: "",
              //   present_upazilla: "",
              //   photo: "",
              //   per_address: "",
              //   per_post_code: "",
              //   per_district: "",
              //   per_upazilla: "",
              //   home_district: "",
              //   home_upazilla: "",
              //   mobile: "",
              //   alternative_mobile: "",
              //   religion: "",
              //   ethnic_group: "",
              //   highest_class_completed: "",
              //   highest_class_completed_year: "",
              //   is_employed: "",
              //   year_of_experience: "",
              //   family_monthly_income: "",
              //   is_physically_challenged: "",
              //   challenge_remarks: "",
              //   mother_name: "",
              //   mother_education_level: "",
              //   mother_occupation: "",
              //   father_name: "",
              //   father_education_level: "",
              //   father_occupation: "",
              //   father_annual_income: "",
              //   have_family_owned_home: "",
              //   have_family_owned_land: "",
              //   number_of_siblings: "",
              // };
              //this.$router.push("/sign-up");
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
        });
    },

    async getDistrictForEmp() {
      await ApiService.get('configurations/geo_district/list')
        .then((response) => {
          this.empDistricts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEthnicGroup() {
      await ApiService.get('configurations/ethnic_group/list')
        .then((response) => {
          this.ethnicGroups = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getSubDistrict() {
      await ApiService.get(
        'configurations/geo_upazila/list?district=' +
          this.formData.present_district
      )
        .then((response) => {
          this.subDistricts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getPermanentSubDistrict() {
      await ApiService.get(
        'configurations/geo_upazila/list?district=' + this.formData.per_district
      )
        .then((response) => {
          this.subPermanentDistrict = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getHomeSubDistrict() {
      await ApiService.get(
        'configurations/geo_upazila/list?district=' +
          this.formData.home_district
      )
        .then((response) => {
          this.subHomeSubDistrict = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const TraineeListSchema = Yup.object().shape({
      entity_info_id: Yup.string().required().label('Association'),
      tranche_id: Yup.string().required().label('Tranche'),
      target_year_id: Yup.string().required().label('Target Year Id'),
      target_year: Yup.string().required().label('Target Year'),
      target_trainee_number: Yup.string()
        .required()
        .label('Target Trainee Number'),
    });
    return {
      TraineeListSchema,
    };
  },
});
